<template>
    <div class="summary_list" :class="$route.path.includes('/index2') ? 'summary_list2' : '' ">
        <!-- <div id="cusNum" v-if="$route.path.includes('/index2')">客户总数<span>10</span>家</div> -->
        <el-popover
            v-if="$route.path.includes('/index2')"
            trigger="hover"
            id="cusNum"
            >
            <template #reference>
                <div>客户总数<span>10</span>家</div>
            </template>
            <template #default>
                <div class="cusList">
                    <div v-for="(item,index) in customerList" :key="index">
                        <p v-if="item.name != '全部医院'" @click="goCustomer(item)">{{ item.name }}</p>
                    </div>
                </div>
            </template>
        </el-popover>
        <ul>
            <li v-for="(item, index) in list" :key="index">
                <div class="text_count padding-bottom-lg countNum">{{ item.count }}</div>
                <span class="text_name fs-md countWarpper">
                    <i class="el-icon-menu countNameIcon"></i>
                    <span class="countName">{{ item.name }}</span>
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
import * as api from '@/api/index'
import { formatTime } from '@/utils/index'
export default {
    data() {
        return {
            list: [
                {
                    name: '今日报废数量',
                    count: 87
                },
                {
                    name: '今日登记数量',
                    count: 243
                },
                {
                    name: '今日出库数量',
                    count: 1000
                },
                {
                    name: '今日芯片更换',
                    count: 13
                },
            ],
            params: {
                // pageNum: 1,
                // pageSize: 10000,
                // startTime: '',
                // endTime: '',
                customerId: ''
            },
        }
    },
    props: {
        customerList: {
            type: Array,
            default: () => ([])
        },
    },
    mounted() {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        this.params.customerId = this.$route.query.customerId

        if(this.$route.path.includes('/index2')){
            this.getData2()
        }else{
            this.getData()
        }
    },
    methods: {
        goCustomer(item){
            window.open(window.location.origin + '/#/index?customerId=' + item.id+ '&customerName='+ item.name, '_blank');
        },

        async getData() {
            const res = await api.getSkuCountToday(this.params)
            if (res.ResultCode == 0) {
                // console.log(res.Result.numOut, '今日登记，报废，出库，芯片更换-----------')

                this.list.map(v => {
                    if (v.name == '今日报废数量') {
                        v.count = res.Result.numDispose
                    } else if (v.name == '今日登记数量') {
                        v.count = res.Result.numRegister
                    } else if (v.name == '今日出库数量') {
                        v.count = res.Result.numOut
                    } else if (v.name == '今日芯片更换') {
                        v.count = res.Result.numChange
                    }
                    return v
                })
            }
        },
        async getData2() {
            this.list = [
                {
                    name: '今日收污数量',
                    count: 0
                },
                {
                    name: '管理织物总数量',
                    count: 0
                },
                {
                    name: '今日配送数量',
                    count: 0
                },
            ]
            const res = await api.selectCustomer()
            const cusNum = res.data.length

            let params =  {
                pageNum: 1,
                pageSize: 10,
                startTime: formatTime(new Date(),'yyyy-MM-dd'),
                endTime: formatTime(new Date(),'yyyy-MM-dd'),
            }
            const res2 = await api.getBigSkuCount(params)
           
            const numDelivery = res2.Result[0]?.numDelivery || 0
            const numReceive = res2.Result[0]?.numReceive   || 0

            const res3 = await api.getSkuCount({})
            const skuNum = res3.data || 0
                
            this.list.map(v => {
                if (v.name == '今日收污数量') {
                    v.count = numReceive
                } else if (v.name == '管理织物总数量') {
                    v.count = skuNum
                } else if (v.name == '今日配送数量') {
                    v.count = numDelivery
                }
                return v
            })
        },
    }
}
</script>
<style lang="scss" scoped>
#cusNum{
    //font-weight: bold;
    font-size: 22px;
    position: absolute;
    top: 8%;
    z-index: 999;
    border: 0.5px solid #00cdff;
    padding: 6px 20px;
    border-radius: 10px;
    span{
        color: #00cdff;
    }
}
.summary_list {
    //padding-top: 25px;
    //padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    ul {
        //display: grid;
        //grid-template-columns: 20% 20% 20% 20%;
        display: flex;
        flex-direction: row;
    
        justify-content: space-around;
        align-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 0 5%;
        li {
            text-align: center;

            .text_count {
                color: #00cdff;
                font-size: 24px;
            }

            .text_name {
                i {
                    color: #00cdff;
                }
            }
        }
    }
}
.summary_list2{
    display: flex;
    flex-direction: column;
    /*
li:nth-child(2){
        position: relative;
        top: -10px;
    }
    */
    li:nth-child(1),li:nth-child(3){
        position: relative;
        top: 20px;
    }
}
.cusList{
    p{
        padding: 5px 0;
        cursor: pointer;
    }
    p:hover{
        color: #00cdff;
    }
}
</style>